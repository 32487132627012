import Vue from 'vue';
import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { DateTime as LuxonDateTime } from 'luxon';

import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';
import './BookingCalendar.css';
import { BookingModel } from '../Bookings/Bookings';

export interface BookingCalendarData {
  calendarInstance: Calendar | null;
  selectedDay: string;
}

const BookingCalendar = Vue.extend({
  beforeDestroy() {
    this.calendarInstance?.destroy();
  },

  data(): BookingCalendarData {
    return {
      calendarInstance: null,
      selectedDay: '',
    };
  },

  methods: {
    /**
     * Watch for changes on the "groupedBooking" value in the store and add it to the events array
     */
    addEventsToCalendar() {
      const store = this.$store;
      store.subscribe((mutation, state) => {
        if (mutation.type !== 'updateGroupedBookings') return;

        this.loadEventsToCalendar(state);
      });
    },

    createCalendar() {
      const calendarElement = document.getElementById('bookingCalendar');

      if (!calendarElement) return;
      const self = this;

      this.calendarInstance = new Calendar(
        calendarElement,
        {

          dateClick({ dateStr }) {
            self.selectedDay = dateStr;
          },

          defaultView: 'dayGridMonth',

          eventClick({ event }) {
            const date = event.start;

            if (!date) return;

            self.selectedDay = LuxonDateTime.fromJSDate(date).toISODate();
          },

          plugins: [
            dayGridPlugin,
            interactionPlugin,
          ],
        },
      );

      this.calendarInstance.render();
    },

    loadEventsToCalendar(state?: any) {
      const { groupedBookings } = state || this.$store.state;
      const eventSourceId = 'ah-event-source';

      if (!this.calendarInstance) return;

      if (this.calendarInstance.getEventSources().length > 0) {
        this.calendarInstance.getEventSourceById(eventSourceId)?.remove();
      }

      const events: any = [];

      Object.keys(groupedBookings).forEach((key) => {
        const val = groupedBookings[key] as BookingModel[];

        const confirmed = val.filter(i => i.confirmed);
        const pending = val.length - confirmed.length;

        if (confirmed) {
          events.push({
            className: 'success',
            start: key,
            title: `${confirmed.length} approved booking${confirmed.length > 1 ? 's' : ''}`,
          });
        }

        if (pending) {
          events.push({
            className: 'pending',
            start: key,
            title: `${pending} pending booking${pending > 1 ? 's' : ''}`,
          });
        }
      });

      this.calendarInstance?.addEventSource({
        events,
        id: eventSourceId,
      });
    },

    loadSelectedDayData() {
      this.$emit('changeSelectedDay', {
        dateStr: this.selectedDay,
      });
    },
  },

  mounted() {
    this.createCalendar();

    this.addEventsToCalendar();

    this.loadEventsToCalendar();
  },

  name: 'booking-calendar',

  render() {
    return (
      <div>
        <style>
          {
            `
              [data-date="${this.selectedDay}"] {
                background-color: #ddd !important;
              }
            `
          }
        </style>
        <div id="bookingCalendar"></div>
      </div>
    );
  },

  watch: {
    $route: 'loadEventsToCalendar',
    selectedDay: 'loadSelectedDayData',
  },
});

export default BookingCalendar;
